<template>
    <div class="confirmation">
        <div class="container-fluid full-height">
            <div class="row row-height">
                <div class="col-lg-12 content-left">
                    <div class="content-left-wrapper">
                        <a href="/" id="logo"><img src="../assets/logo_negativo.png" alt="" width="175"></a>
                        <SocialIcons></SocialIcons>
                        <div>
                            <figure><img src="../assets/complete.png" alt="" class="img-fluid"></figure>
                            <h2>Grazie!</h2>
                            <h2>
                                Le tue risposte sono state inviate correttamente!
                            </h2>
                            <p>
                                RetImpresa Reference sta elaborando il tuo benchmark qualitativo in base agli
                                obiettivi scelti. <br>Appena il report sarà disponibile riceverai una
                                mail per visualizzare l'elaborato.
                            </p>
                            <a href="https://www.retimpresa.it" target="_blank" class="btn_1 rounded mr-3">Scopri di più su Reference</a>
                            <a href="mailto:retimpresa@confindustria.it" class="btn_1 rounded">Inviaci un feedback</a>
                        </div>
                        <div class="copy">© 2020 RetImpresa Reference</div>
                    </div>
                </div>
                <!-- /content-left -->
            </div>
            <!-- /row-->
        </div>
        <!-- /container-fluid -->

        <BannerCta/>
    </div>
</template>

<script>
  import { Component, Vue } from 'vue-property-decorator';
  import BannerCta from "@/components/BannerCta.vue";
  import SocialIcons from "@/components/SocialIcons";

  @Component({
    components: {
      BannerCta,
      SocialIcons
    },
  })

  export default class Confirm extends Vue {}
</script>

<style scoped>

</style>
